<template>
  <p style="opacity:0.1;"></p>
</template>
    
<script>
export default {
  data() {
    return {};
  }
};
</script>

<style></style>